.preloader-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

.preloader-ring {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  /*box-shadow:1px 5px 2px #ff002e;*/
  background: transparent;
  animation: ani 2s linear infinite;
}

.preloader-center__text_container {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  color: #fff;
  position: absolute;
  text-align: center;
  top: 0;
  left: 0;
  font-size: 2em;
  background: transparent;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.preloader-center__is-rn-image {
  width: 76%;
  margin-bottom: 10px;
}

.preloader-center__text {
  font-size: 15px;
  margin: 0px;
}

@keyframes ani {
  0% {
    transform: rotate(0deg);
    box-shadow: 1px 5px 2px #ff002e;
  }

  50% {
    transform: rotate(180deg);
    box-shadow: 1px 5px 2px #18a401;
  }
  100% {
    transform: rotate(360deg);
    box-shadow: 1px 5px 2px #033e8e;
  }
}
